import React from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/core';
import { Link } from 'gatsby';
import { MdxArticle } from '../types/Article';
import Layout, { Wrapper } from '../layouts/Layout';
import { Typography } from '@material-ui/core';
import styled from '../styled';
import { SEO } from '../components/SEO';
import { Branding } from '../components/Branding';
import { Tags } from '../components/Tags';
import { ENABLE_PAID_BLOG_POSTS } from '../constants';

type PageQueryData = {
  allMdx: {
    totalCount: number;
    edges: {
      node: MdxArticle;
    }[];
  };
};

const Grid = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(4)}px;
  grid-row-gap: ${(p) => p.theme.spacing(2)}px;

  & > div {
    margin-bottom: 12px;
  }
`;

const COLOR = '#69c0ff';

const StyledLink = styled(Link)`
  ${({ theme }) => css`
    color: ${COLOR};
    font-size: ${theme.typography.h4.fontSize};
    font-weight: 700;

    @media (max-width: 800px) {
      font-size: ${theme.typography.h5.fontSize};
    }
  `};
`;

const PostContainer = styled('div')`
  ${({ theme }) => css`
    max-width: 1000px;
    padding: ${theme.spacing(2)}px;
    margin: ${theme.spacing(4)}px auto;

    @media (max-width: 800px) {
      padding: ${theme.spacing(1)}px;
      margin: ${theme.spacing(2)}px auto;
    }
  `};
`;

const PaperInner = styled('div')`
  padding: ${(p) => p.theme.spacing(2)}px ${(p) => p.theme.spacing(4)}px
    ${(p) => p.theme.spacing(6)}px;
  text-align: left;
`;

const PostSummary = styled('p')`
  ${({ theme }) => css`
    color: #eee;
    font-size: ${theme.typography.h6.fontSize};
    line-height: ${theme.typography.h6.lineHeight};
  `};
`;

const PostLink = ({ post }: { post: MdxArticle }) => (
  <div style={{ height: '100%', position: 'relative' }}>
    <PaperInner>
      <h2>
        <StyledLink to={post.fields.slug}>{post.frontmatter.title}</StyledLink>
      </h2>
      <PostSummary>{post.frontmatter.summary}</PostSummary>
      <Tags tags={post.frontmatter.tags} />
    </PaperInner>
  </div>
);

const IndexPage = ({
  pageContext: { tag },
  data: {
    allMdx: { edges }
  }
}: {
  pageContext: { tag: string };
  data: PageQueryData;
}) => {
  const Posts = edges
    .filter((edge) => {
      if (!ENABLE_PAID_BLOG_POSTS) {
        return edge.node.frontmatter.tags.indexOf('paid') === -1;
      }
      return true;
    })
    .map((edge) => (
      <div key={edge.node.fields.slug}>
        <PostLink post={edge.node} />
      </div>
    ));

  return (
    <Layout>
      <SEO
        title={`Blog Posts on ${tag}`}
        siteUrl="https://bloggingfordevs.com"
        description="Get advice and resources for creating a strategy for your developer blog, and create content that reaches thousands without an existing audience."
        noIndex
        pathname={`tags/${tag}/`}
      />
      <Wrapper>
        <div
          style={{
            maxWidth: '800px',
            padding: '16px',
            margin: '0 auto',
            textAlign: 'center'
          }}
        >
          <Branding to="/">Blogging for Devs</Branding>
          <Typography variant="h4" component="h1" gutterBottom>
            Articles on {tag}
          </Typography>
          <Link to="/blog/" style={{ color: '#999' }}>
            Back to All Posts
          </Link>
        </div>
        <PostContainer>
          <Grid>{Posts}</Grid>
        </PostContainer>
      </Wrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($tag: String) {
    allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            summary
            tags
          }
        }
      }
    }
  }
`;

export default IndexPage;
